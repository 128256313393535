import React from 'react';
import { Helmet } from 'react-helmet';

const OutbrainHeadTags = ({ hostname }) => {
    const getHeadTags = () => {
        switch (hostname) {
            case 'insurepioneer.com':
                return (
                    <Helmet>
                        <link rel='dns-prefetch' href='//exmarketplace.com' />
                        <link rel='preconnect' href='//cdn.exmarketplace.com' />
                        <script
                            async
                            src='https://cdn.exmarketplace.com/bidder/adn/insurepioneer/insurepioneer.dfp.js'
                        ></script>
                    </Helmet>
                );
            case 'smartcreditline.com':
                return (
                    <Helmet>
                        <link rel='dns-prefetch' href='//exmarketplace.com' />
                        <link rel='preconnect' href='//cdn.exmarketplace.com' />
                        <script
                            async
                            src='https://cdn.exmarketplace.com/bidder/adn/smartcreditline/smartcreditline.dfp.js'
                        ></script>
                    </Helmet>
                );
            case 'procasacraft.com':
                return (
                    <Helmet>
                        <link rel='dns-prefetch' href='//exmarketplace.com' />
                        <link rel='preconnect' href='//cdn.exmarketplace.com' />
                        <script
                            async
                            src='https://cdn.exmarketplace.com/bidder/adn/procasacraft/procasacraft.dfp.js'
                        ></script>
                    </Helmet>
                );
            case 'smarttechtalks.com':
                return (
                    <Helmet>
                        <link rel='dns-prefetch' href='//exmarketplace.com' />
                        <link rel='preconnect' href='//cdn.exmarketplace.com' />
                        <script
                            async
                            src='https://cdn.exmarketplace.com/bidder/adn/smarttechtalks/smarttechtalks.dfp.js'
                        ></script>
                    </Helmet>
                );
            case 'nomadicnotes.net':
                return (
                    <Helmet>
                        <link rel='dns-prefetch' href='//exmarketplace.com' />
                        <link rel='preconnect' href='//cdn.exmarketplace.com' />
                        <script
                            async
                            src='https://cdn.exmarketplace.com/bidder/adn/nomadicnotes/nomadicnotes.dfp.js'
                        ></script>
                    </Helmet>
                );
            case 'moneymasterymatters.com':
                return (
                    <Helmet>
                        <link rel='dns-prefetch' href='//exmarketplace.com' />
                        <link rel='preconnect' href='//cdn.exmarketplace.com' />
                        <script
                            async
                            src='https://cdn.exmarketplace.com/bidder/adn/moneymasterymatters/moneymasterymatters.dfp.js'
                        ></script>
                    </Helmet>
                );
            case 'culinarycreationscorner.com':
                return (
                    <Helmet>
                        <link rel='dns-prefetch' href='//exmarketplace.com' />
                        <link rel='preconnect' href='//cdn.exmarketplace.com' />
                        <script
                            async
                            src='https://cdn.exmarketplace.com/bidder/adn/culinarycreationscorner/culinarycreationscorner.dfp.js'
                        ></script>
                    </Helmet>
                );
            case 'activewellnessways.com':
                return (
                    <Helmet>
                        <link rel='dns-prefetch' href='//exmarketplace.com' />
                        <link rel='preconnect' href='//cdn.exmarketplace.com' />
                        <script
                            async
                            src='https://cdn.exmarketplace.com/bidder/adn/activewellnessways/activewellnessways.dfp.js'
                        ></script>
                    </Helmet>
                );
            case 'homehelptoday.net':
                return (
                    <Helmet>
                        <script type="text/javascript" async="" src="//widgets.outbrain.com/outbrain.js"></script>
                    </Helmet>
                );
            case 'wellcarespot.com':
                return (
                    <Helmet>
                        <script type="text/javascript" async="" src="//widgets.outbrain.com/outbrain.js"></script>
                    </Helmet>
                );
            case 'smartfinancetoday.net':
                return (
                    <Helmet>
                        <script type="text/javascript" async="" src="//widgets.outbrain.com/outbrain.js"></script>
                    </Helmet>
                );
            default:
                return null;
        }
    };

    return getHeadTags();
};

export default OutbrainHeadTags;